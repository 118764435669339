import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import Text from "../shared/Text"
import Link from "../shared/Link"
import { Wrapper } from "./styled"

const News = ({ text, date, link, linkText }) => {
    return (
        <Fade>
            <Wrapper>
                {date && (
                    <Text
                        variant="h4"
                        align="center"
                        style={{ marginBottom: 15 }}
                    >
                        {date}
                    </Text>
                )}
                {text && (
                    <Text
                        variant="p"
                        align="center"
                        style={{
                            maxWidth: 525,
                            margin: "0 auto",
                            marginBottom: link ? 40 : 0,
                        }}
                    >
                        {text}
                    </Text>
                )}
                {link && (
                    <Link to={link} variant="moreButton">
                        {linkText || "подробнее"}
                    </Link>
                )}
            </Wrapper>
        </Fade>
    )
}

News.propTypes = {
    text: PropTypes.string,
    date: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
}

export default News
