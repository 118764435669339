import React from "react"

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="1.32 1.36 368.98 464.379"
        >
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M148.659 441.732a1 1 0 01.488 1.327l-10.2 22.08a1 1 0 11-1.816-.839l10.2-22.079a1 1 0 011.328-.489z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M222.968 211.142a1 1 0 01.769 1.187l-4.56 21.36-.003.016-4.92 21.36-.007.028-5.52 21.12-.004.016-5.88 21.12-.005.016-6.24 21-.007.023-6.72 20.76-.005.015-7.08 20.76-.008.023-7.56 20.52-.008.021-8.04 20.4-.005.011-8.28 20.28-.009.023-8.76 20.04a1 1 0 01-1.833-.801l8.755-20.029 8.271-20.257.005-.012 8.031-20.378 7.552-20.498 7.071-20.734 6.716-20.748 6.234-20.98 5.876-21.104 5.514-21.098 4.915-21.338 4.559-21.352a1 1 0 011.186-.77zM244.496 1.36a1 1 0 01.984 1.016l-.36 22.32-.001.022-.84 22.44-.001.016-1.2 22.32-.001.027-1.8 22.32-.002.016-2.16 22.32-.002.021-2.64 22.2-.003.022-3.12 22.2-.003.021-3.6 22.08-.004.022-4.08 21.96a1 1 0 11-1.966-.365l4.078-21.949 3.596-22.059 3.116-22.168.003-.021 2.636-22.168 2.158-22.301 1.798-22.299 1.199-22.298.839-22.421.36-22.31a1 1 0 011.016-.984zM137.039 464.72a1 1 0 011-1h26.4a1 1 0 110 2h-26.4a1 1 0 01-1-1zM262.705 1.36a1 1 0 011.016.984l.359 22.31.84 22.418 1.318 22.301 1.679 22.302 2.158 22.295 2.756 22.171.002.016 3.116 22.168.002.016 3.475 22.05 4.075 21.931.003.016 4.435 21.933 4.917 21.83 5.39 21.677.005.021 5.87 21.562.005.017 6.229 21.442.006.021 6.707 21.318.007.021 7.19 21.21.003.009 1.671 4.776.006.015 6.834 18.585a1 1 0 11-1.877.691l-6.84-18.601-.005-.014-1.68-4.8-.003-.01-7.2-21.24-.007-.021-6.72-21.36-.007-.021-6.24-21.48-.004-.016-5.88-21.6-.006-.021-5.4-21.72-.005-.022-4.92-21.84-.004-.021-4.44-21.96-.003-.016-4.08-21.96a.256.256 0 01-.005-.027l-3.48-22.08-.003-.016-3.12-22.2-.002-.016-2.76-22.2-.003-.027-2.16-22.32-.001-.022-1.68-22.32-.002-.016-1.32-22.32-.001-.021-.84-22.44v-.021l-.36-22.32a1 1 0 01.984-1.017zM333.513 381.709a1 1 0 011.297.565l8.156 20.75 8.631 20.62 9.113 20.502 9.356 20.153a.999.999 0 01-.907 1.421h-26.4a1 1 0 010-2h24.833l-8.7-18.739-.007-.015-9.12-20.52-.008-.02-8.64-20.64-.009-.02-8.16-20.76a1 1 0 01.565-1.297z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M303.824 405.577a1 1 0 011.389-.264 91.687 91.687 0 0138.601 59.246 1 1 0 11-1.967.361 89.687 89.687 0 00-37.759-57.953 1 1 0 01-.264-1.39zM227.131 393.363a91.648 91.648 0 0173.714 9.15 1 1 0 11-1.029 1.714 89.657 89.657 0 00-72.106-8.95 89.704 89.704 0 00-55.066 47.412 1 1 0 11-1.807-.858 91.71 91.71 0 0156.294-48.468zM171.042 443.745a.999.999 0 01.514 1.317 90.035 90.035 0 00-6.092 19.849 1 1 0 11-1.967-.362 91.995 91.995 0 016.228-20.29 1 1 0 011.317-.514zM55.213 211.192c61.389-40.064 141.488-36.533 199.115 8.772a1 1 0 01-1.236 1.572c-56.953-44.775-136.115-48.264-196.786-8.669a1 1 0 11-1.093-1.675z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M55.839 211.955a1 1 0 01-.293 1.383 114.866 114.866 0 000 192.564 1 1 0 11-1.091 1.676 116.865 116.865 0 010-195.916 1 1 0 011.384.293zM243.48 2.36a1 1 0 011-1h18.24a1 1 0 110 2h-18.24a1 1 0 01-1-1z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M321.083 393.806a1 1 0 01-.139 1.408c-76.292 62.59-184.688 67.583-266.424 12.285a1 1 0 011.12-1.657c81.004 54.802 188.428 49.855 264.036-12.175a1 1 0 011.407.139z"
                clipRule="evenodd"
            ></path>
            <path
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M369.878 341.594a.999.999 0 01.238 1.394 258.325 258.325 0 01-49.25 52.292 1 1 0 11-1.251-1.56 256.354 256.354 0 0048.869-51.888 1 1 0 011.394-.238z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
