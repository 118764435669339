import React from "react"

import SEO from "../components/shared/Seo"
import NewsSection from "../sections/News"
import Layout from "../components/shared/Layout"

const NewsPage = () => (
    <Layout>
        <SEO title="О студии" />
        <NewsSection />
    </Layout>
)

export default NewsPage
