import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Wrapper = styled.article`
    width: 100%;
    padding: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
    background-color: hsla(223, 8%, 83%, 1);

    ${media.md} {
        padding: 50px;
    }

    button {
        background-color: transparent;
    }
`
