import styled from "styled-components"

import { colors, media } from "../../helpers/variables"
import { Ul as UL } from "../shared/styled"

export const Footer = styled.footer`
    width: 100%;
    background-color: ${colors.green};
`

export const Wrapper = styled.div`
    display: flex;
    padding: 50px 0;
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    ${media.lg} {
        max-width: 100%;
        flex-direction: row;
    }
`

export const Logo = styled.div`
    width: 100%;
    display: flex;
    max-width: 215px;
    flex-direction: column;

    svg {
        max-width: 115px;
    }

    a {
        padding-top: 25px;
    }
`

export const Ul = styled(UL)`
    display: flex;
    margin-top: 50px;
    flex-direction: column;

    ${media.lg} {
        margin-top: 0;
    }

    h5,
    div:not(:last-of-type) {
        padding-bottom: 18px;
    }
`
