import React from "react"

import { CATEGORIES, ROUTES } from "../../helpers/variables"
import Link from "../shared/Link"
import Text from "../shared/Text"
import InstagramIcon from "../SVG/Instagram"
import LogoIcon from "../SVG/Logo"
import { Footer, Logo, Ul, Wrapper } from "./styled"

const FooterComponent = () => (
    <Footer id="footer">
        <div className="container">
            <Wrapper>
                <Logo>
                    <LogoIcon />
                    <Link to={ROUTES.HOME} color="white">
                        Студия Дизайна
                    </Link>
                </Logo>

                <Ul>
                    <Text
                        variant="h5"
                        color="white"
                        style={{ paddingBottom: 30 }}
                    >
                        Проекты
                    </Text>
                    <Link
                        color="white"
                        to={ROUTES.ARCH}
                        textTransform="inherit"
                    >
                        {CATEGORIES.ARCH}
                    </Link>
                    <Link
                        color="white"
                        textTransform="inherit"
                        to={ROUTES.INTER}
                    >
                        {CATEGORIES.INTER}
                    </Link>
                    <Link
                        color="white"
                        to={ROUTES.CONCEPT}
                        textTransform="inherit"
                    >
                        {CATEGORIES.CONCEPT}
                    </Link>
                </Ul>

    {/*            <Ul>
                    <Link
                        color="white"
                        to={ROUTES.NEWS}
                        textTransform="inherit"
                    >
                        О студии
                    </Link>
                </Ul>
    */}            

                <Ul>
                    <Link
                        color="white"
                        to={ROUTES.CONTACTS}
                        textTransform="inherit"
                    >
                        Контакты
                    </Link>
                </Ul>
            </Wrapper>
        </div>
    </Footer>
)

export default FooterComponent
