import React from "react"
import Fade from "react-reveal/Fade"

import { Section } from "./styled"
import news from "../../../data/news.json"
import News from "../../components/News"
import Text from "../../components/shared/Text"

const NewsSection = () => {
    return (
        <Section>
            <div className="container">
                <Fade top>
                    <Text
                        variant="h1"
                        align="center"
                        style={{ marginBottom: 60 }}
                    >
                        Коллектив Студии
                    </Text>
                </Fade>

                {news &&
                    Array.isArray(news) &&
                    news.map(n => (
                        <News
                            key={n.id}
                            text={n.text}
                            date={n.date}
                            link={n.link}
                            linkText={n.linkText}
                        />
                    ))}
            </div>
        </Section>
    )
}

export default NewsSection
